import React, { useState } from 'react'
import Popup from './Popup/Popup';

const Section = ({products}) => {
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
      };
    
      // Function to close the popup
      const closePopup = () => {
        setSelectedProduct(null);
      };
  return (
    <div className='flex flex-col'>
        
        {products.map(product => (
            <div onClick={()=>handleProductClick(product)} className="flex w-full py-4 border-b border-gray-100 h-28">
                <div className="flex flex-col w-full px-2 pr-2">
                    <span className='font-semibold text-gray-800'>{product.name}</span>
                    <div className="flex">
                    <span className='mr-2 text-base font-semibold text-gray-900'>${((product.price.fractional / 100)/2).toFixed(2)}</span>
                    <span className='text-sm text-gray-500 line-through'>{product.price.formatted}</span>

                    </div>
                </div>
                
                <div className="flex-shrink-0 h-full pr-2 aspect-square">

                <img src={`/assets/img/products/${product.image.url}`} className='object-cover w-full h-full' alt="" />
                </div>
                <div className="flex items-center justify-center p-2 ml-2 border rounded">
                <svg stroke="#00CCBC" fill="#00CCBC" stroke-width="0" t="1551322312294" viewBox="0 0 1024 1024" version="1.1" pId="10297" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><defs></defs><path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" pId="10298"></path><path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" pId="10299"></path></svg>
                </div>

            </div>
        ))}
        {selectedProduct && (
            <Popup selectedProduct={selectedProduct} closePopup={closePopup}/>
      )}
    </div>
  )
}

export default Section