import React, { useState, useEffect } from 'react';

const CustomSelect = ({ options, selectedOptions, setSelectedOptions, groupIndex }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelect = (option) => {
    // Update the selected option for this CustomSelect instance
    setSelectedOption(option);

    // Update the selectedOptions state in parent, keeping other groups' selections intact
    setSelectedOptions((prev) => {
      const updatedSelections = [...prev];
      updatedSelections[groupIndex] = option;
      return updatedSelections;
    });
  };

  return (
    <div className="flex flex-col w-full space-y-6">
      {options.map((option) => (
        <div
          key={option.id}
          onClick={() => handleSelect(option)}
          className={`flex flex-shrink-0 justify-between items-center cursor-pointer mb-2 rounded-md`}
        >
          <span className="w-2/3 text-sm text-gray-500">{option.name}</span>
          <div className="flex">
            {option.price.fractional > 0 && (
              <span className="mr-1 text-sm text-gray-400">
                +{option.price.formatted}
              </span>
            )}
            <div
              className={`w-5 h-5 flex items-center justify-center flex-shrink-0 border-2 rounded-full ${
                selectedOption?.id === option.id
                  ? 'border-prim_blue'
                  : 'border-gray-200'
              }`}
            >
              {selectedOption?.id === option.id && (
                <div className="w-3 h-3 rounded-full bg-prim_blue"></div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CustomSelect