import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';

const Navbar = ({ cats }) => {
  const [isSticky, setIsSticky] = useState(false);


  // Reference to the container that holds the links
  const containerRef = useRef(null);
  const sectionRefs = useRef({});

  // Function to handle scroll event
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    if (scrollTop > 462) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  // Scroll to the active section
  const scrollToActiveSection = (section) => {
    const activeSection = sectionRefs.current[section];
    if (activeSection && containerRef.current) {
      // Scroll the container to the active section's offset
      containerRef.current.scrollTo({
        left: activeSection.offsetLeft - 200, // Adjust offset to align properly
        behavior: 'smooth',
      });
    }
  };

  // Use useEffect to listen to scroll event on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
<nav
  className={`${
    isSticky ? 'fixed tcusz top-0 left-0 w-full shadow' : 'relative'
  } transition-all flex bg-white duration-300 pt-4 `}
>
  <div
    className={`${
        isSticky ? 'flex items-center w-full px-2 py-6 overflow-x-scroll' : 'flex items-center w-full px-2 overflow-x-scroll'}`}
    // className="flex items-center w-full px-2 py-6 overflow-x-scroll" // Enables horizontal scrolling
    ref={containerRef} // Reference to the scrollable container
  >
    {cats.map((cat) => (
      <Link
        key={cat.id}
        to={`${cat.id}-section`}
        smooth={true}
        duration={500}
        spy={true}
        activeClass="bg-prim_blue rounded-full text-white" // Active class styles
        className="flex items-center justify-center h-full pb-2 mr-3 cursor-pointer w-fit whitespace-nowrap" // Prevents text wrapping
        onSetActive={() => scrollToActiveSection(cat.id)} // Trigger scroll when section becomes active
      >
        <div
          ref={(el) => (sectionRefs.current[cat.id] = el)} // Reference to each section link
          className="w-0 h-8 mr-2 overflow-hidden rounded-full "
        ></div>
        <span className="px-2 text-sm font-semibold">{cat.name}</span>
      </Link>
    ))}
  </div>
</nav>

  );
};

export default Navbar;
